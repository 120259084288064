import Card from "../Components/share/Card"
import key1 from "../images/10Kimages/Key1.png"
import key2 from "../images/10Kimages/Key2God'sHealing.png"
import key3 from '../images/10Kimages/Key3TeachableHearts.png'
import key4 from "../images/10Kimages/Key4God'sWord.png"
import key5 from "../images/10Kimages/Key5God'sPresence.png"
import key6 from '../images/10Kimages/Key6Panel3.png'
import key61 from '../images/10Kimages/Key6Panel 1.png'
import key62 from '../images/10Kimages/Key6Panel 2.png'
import key7 from "../images/10Kimages/Key7God'sNurturing.png"
import key8 from '../images/10Kimages/Key8Communion.png'
import key9 from '../images/10Kimages/Key9Renewal ofMinds.png'
import key10 from '../images/10Kimages/Key10AbidingGivers.png'

function TenKeys() {
  return (
    <>
      <Card>
        <div className='bg-green-100 text-custom-light-green'>
          <div className='flex flex-col items-center justify-center mt-10 p-7'>
            <div className='sm:text-2xl  py-4 font-bold md:text-5xl '>
              10 Spiritual Keys For Thriving Together in Today’s World
            </div>
            <div className='font-bold md:text-2xl mb-10 text-black'>
              Key: a tool to unlock; a tool to start something; a tool used
              often to unlock and open a door.
            </div>
            <div className='font-bold md:text-3xl'>
              Learn More About One Key &#x2015; Together &#128525; &#128525;
              &#128525; &#128525;
            </div>
          </div>
          <div className='font-bold text-center md:text-3xl mt-20'>
            {' '}
            As you start:
          </div>
          <div className='flex flex-col items-start p-9 md:text-2xl '>
            <div className='pb-7'>
              &#10070; <strong>Ask</strong> God’s Holy Spirit for guidance. Pray
              &#x2015; anticipating insights.
            </div>
            <div className='pb-7'>
              &#x2756; <strong>Review </strong> where the 10 Keys are located in
              the Four-Petal Sunflower Picture. (Found on home page.)
            </div>
            <div>
              &#x2756; <strong>Remember </strong>these cool thoughts: One
              sunflower is made up of many little flowers &#x2015; it’s a
              community!
              <h5 className='md:pl-96'>
                Similarly, each Four-Petal Sunflower represents a community of
                people!
              </h5>
            </div>
          </div>
          <div className='flex items-center justify-center mt-20'>
            <h5 className='font-bold md:text-3xl'>Three Together Steps:</h5>
          </div>
          <div className='flex flex-col items-start p-9 md:text-2xl'>
            <div className='pb-7'>
              <strong>1. Look</strong> below and choose one of the ten Keys.
            </div>
            <div className='pb-7'>
              <strong>2. Read</strong> out loud all or some of the Bible verses
              for your key.
            </div>
            <div>
              <strong>3. Enjoy </strong> talking about anything sparked by #1
              and #2 steps. Only If helpful, consider one of the following:
            </div>
          </div>
          <div className='flex flex-col sm:ml-20 sm:mr-10 items-start md:ml-40 md:text-2xl'>
            <div>
              &#10070; Do you have any thoughts, impressions or feelings about
              your key?
            </div>
            <div>
              &#10070; How does your key help you connect with God’s love?
            </div>
            <div>&#10070; How does your key help you love others more?</div>
            <div>
              &#10070; How does your key help you thrive together in today’s
              world?
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                1. God's Glory
              </div>
              <div className='md:text-2xl'>
                The sunflower faces and follows the sun. <br></br>
                We face Jesus and through the Spirit follow Him.
              </div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key1} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                2. God's Healing
              </div>
              <div className='md:text-2xl'>God heals and nurtures us.</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key2} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                3. Teachable Hearts
              </div>
              <div className='md:text-2xl'>Are we good soil?</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key3} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                4. God's Word
              </div>
              <div className='md:text-2xl'>
                On His law we meditate day and night.
              </div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key4} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                5. God's Presence
              </div>
              <div className='md:text-2xl'>We shall never thirst.</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key5} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                6. God's Love Strenghtens Our Hearts
              </div>
              <div className='md:text-2xl'>We anchor in His pure love.</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key61} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key62} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key6} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                7. God's Nurturing
              </div>
              <div className='md:text-2xl'>
                His compassion is with us and among us.
              </div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key7} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>8. Communion</div>
              <div className='md:text-2xl'>Love attachments grow.</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key8} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                9. Renewal of Minds
              </div>
              <div className='md:text-2xl'>
                <h5
                  style={{
                    color: '#006400',
                    textAlign: 'center',
                    marginBottom: '2%'
                  }}
                >
                  We practice God thoughts
                  <small>
                    .
                    <sub style={{ verticalAlign: 'super', fontSize: 'medium' }}>
                      1
                    </sub>
                  </small>
                </h5>
              </div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key9} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
          <div>
            <div className='flex flex-col items-center justify-center mt-20 m-9'>
              <div className='text-2xl font-bold md:text-3xl'>
                10. Abiding Givers
              </div>
              <div className='md:text-2xl'>His yoke is easy</div>
            </div>
            <div className='p-7'>
              <Card>
                <img src={key10} alt='key 1' className='w-full bg-cover' />
              </Card>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default TenKeys
