import React from 'react'
import connectWithUsImage from '../images/ConnectWithUsSunflowertool.png'

function ConnectWithUs() {
  return (
    <div className='flex justify-center items-center min-h-screen p-4'>
      <img
        src={connectWithUsImage}
        alt='connect with Us'
        className="w-full  h-auto rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl"
      />
    </div>
  )
}

export default ConnectWithUs
