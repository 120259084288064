
import React from 'react'
import Card from '../Components/share/Card'
import Slides from '../Components/slides'
import Image from 'react-bootstrap/Image';
import SecondSunflower from '../images/second_sunflower.png'

import ThrivingImage from '../images/thriving_image.png';
import Logo_Bottom from '../images/logo_in_the_bottom.png';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <>
      <Slides />
      <Card>
        <div
          style={{ backgroundColor: '#d7e9f5' }}
          className='max-w-lg mx-auto rounded-xl  overflow-hidden lg:max-w-5xl'
        >
          <div className='md:flex'>
            <div className='p-8'>
              <h1 className='text-center sm:text-2xl md:text-4xl lg:text-5xl font-bold pb-3'>
                What is the Sunflower Tool?
              </h1>
              <p className='font-bold md:text-2xl'>
                It is a simple, visually-based spiritual tool for growing
                wholeness that starts with sunflower analogies. We can practice
                growing closer together in small communities where heart level
                care and communication become the norm.
              </p>
              <div
                className='font-bold md:text-2xl pt-10'
                style={{ color: '#4c81db' }}
              >
                <p className='pb-1'>
                  1. Are you looking for deeper community life?
                </p>
                <p className='pb-1'>
                  2. Are you wanting to become more whole and see its effects in
                  your daily life?
                </p>
                <p className='pb-1'>
                  3. Do you want to know ways for growing inner strength and
                  peace in the harder times?
                </p>
              </div>
              <div className='font-bold md:text-2xl pt-10'>
                <h1 className='pb-3'>
                  If you answered yes to any of these questions, click here
                  <Link to='/sunflowertool'>
                    <button
                      className='btn ml-2 bg-primary text-sm sm:text-base
                  lg:text-lg  font-bold text-yellow-200'
                    >
                      Sunflower Tool
                    </button>
                  </Link>
                </h1>
                <h1>
                  Meet the Four-Petal Sunflower with two overview looks. Check
                  out the many button links to go deeper!
                </h1>
              </div>
              <div>
                <h1 className='text-center sm:text-2xl md:text-3xl lg:text-4xl font-bold pb-3 pt-10'>
                  A Thriving Sunflower Community*
                  <small style={{ color: '#4c81db' }}>
                    {' '}
                    <br />
                    What does the glowing heart represent to you?
                  </small>
                </h1>
              </div>
              <div className='flex flex-col sm:flex-row items-center justify-center sm:space-x-8 py-8'>
                {/* Left Section: Text */}
                <div className='text-center sm:text-left py-4 sm:w-1/2 lg:w-1/3'>
                  <p className='text-lg sm:text-xl leading-relaxed'>
                    A Sunflower Community is a group of two or more disciples of
                    Jesus Christ who practice ways of life that grow their
                    spiritual roots deeper and deeper into God's love. They grow
                    up together in maturity and wholeness by encouraging,
                    caring, and praying with one another.
                  </p>
                </div>
                {/* Middle Section: Image */}
                <div
                  className='flex flex-col items-center justify-center
                sm:w-1/3 md:w-1/4 lg:w-1/4 mb-6 sm:mb-0'
                >
                  <Image
                    className='w-full h-auto rounded-lg shadow-lg'
                    src={ThrivingImage}
                    alt='Thriving Theme image'
                  />
                </div>
                {/* Right Section: Buttons */}
                <div className='text-center sm:text-left py-4 w-full sm:2-1/3 lg:w-1/4'>
                  <button
                    className='btn bg-primary text-sm sm:text-base 
                  font-bold text-yellow-200 mb-4 w-full sm:w-auto'
                  >
                    Four-Petal Sunflower
                  </button>
                  <button
                    className='btn bg-primary text-sm sm:text-base lg:text-lg 
                  font-bold text-yellow-200 mb-4 w-full sm:w-auto'
                  >
                    Immanuel Cycle
                  </button>
                  <button
                    className='btn bg-primary text-sm sm:text-base lg:text-lg
                  font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Testimonies
                  </button>
                </div>
              </div>
              <div>
                <h1 className='text-center sm:text-2xl md:text-3xl lg:text-4xl font-bold pb-3 pt-20'>
                  10 Spiritual Keys for Thriving in Today's World
                  <small style={{ color: '#4c81db' }}>
                    {' '}
                    <br />
                    Is there a key you are most interested in?
                  </small>
                </h1>
              </div>
              <div className='flex flex-col sm:flex-row items-center justify-center sm:space-x-8 py-8'>
                {/* Left Section: Text */}
                <div className='text-center sm:text-left py-4 sm:w-1/2 lg:w-1/3'>
                  <p className='text-lg sm:text-xl leading-relaxed'>
                    Abiding Givers are learning how to live close to God. They
                    are growing to be more like Jesus. They are practicing
                    resting in God and giving from their hearts.
                  </p>
                </div>
                {/* Middle Section: Image */}
                <div
                  className='flex flex-col items-center justify-center
                sm:w-1/3 md:w-1/4 lg:w-1/4 mb-6 sm:mb-0'
                >
                  <Image
                    className='w-full h-auto rounded-lg shadow-lg'
                    src={SecondSunflower}
                    alt='Second Sunflower image'
                  />
                </div>
                {/* Right Section: Buttons */}
                <div className='text-center sm:text-left py-4 w-full sm:2-1/3 lg:w-1/4'>
                  <Link to='/tenkeys'>
                    <button
                      className='btn bg-primary text-sm sm:text-base lg:text-lg
                    font-bold text-yellow-200 mb-4 w-full sm:w-auto'
                    >
                      Ten Keys
                    </button>
                  </Link>
                  <button
                    className='btn bg-primary text-sm sm:text-base lg:text-lg 
                  font-bold text-yellow-200 mb-4 w-full sm:w-auto'
                  >
                    Giving Cycle
                  </button>
                  <button
                    className='btn bg-primary text-sm sm:text-base lg:text-lg
                  font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Connect With Us
                  </button>
                </div>
              </div>
              <div
                className='text-center sm:text-base md:text-base lg:text-2xl font-bold pb-10 pt-20'
                style={{ color: '#4c81db' }}
              >
                <h1 className='pb-2'>
                  "Spiritual growth consists most in the growth of the root,
                  which is out of sight."
                </h1>
                <h1>-Matthew Henry, pastor and Bible commentator 1663-1714</h1>
              </div>
              <div className='text-center sm:text-base md:text-base lg:text-2xl font-bold pb-10 pt-20'>
                Repeated Spirit-led meditation and action steps change us from
                the inside out
              </div>
              <div className='flex items-center justify-center'>
                <button
                  className=' 
              btn bg-primary text-sm sm:text-base lg:text-lg 
              font-bold text-yellow-200 w-full sm:w-auto'
                >
                  Discovery Cycle
                </button>
              </div>
              <div className='flex items-center justify-center pt-10'>
                <button
                  className=' 
              btn bg-primary text-sm sm:text-base lg:text-lg 
              font-bold text-yellow-200 w-full sm:w-auto'
                >
                  Lectio Cycle
                </button>
              </div>
              <div
                className='text-center sm:text-base 
            md:text-base lg:text-2xl font-bold pb-10 pt-20'
                style={{ color: '#4c81db' }}
              >
                <p className='flex items-center justify-center'>
                  Peace seems out of
                </p>
                <p className='flex items-center justify-center'>reach</p>
                <p className='flex items-center justify-center'>
                  One cares for one,
                </p>
                <p className='flex items-center justify-center'>hard places</p>
                <p className='flex items-center justify-center'>
                  We feel the human
                </p>
                <p className='flex items-center justify-center'>-L. Wong</p>
                <p className='flex items-center justify-center'>
                  Southeast Asia
                </p>
              </div>
              <div className='grid grid-cols-2 gap-4 place-content-evenly h-48'>
                <div className='flex items-center justify-center pt-10'>
                  <button
                    className=' 
                btn bg-primary text-sm sm:text-base lg:text-lg 
                font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Nature Cycle
                  </button>
                </div>
                <div className='flex items-center justify-center pt-10'>
                  <button
                    className=' 
                btn bg-primary text-sm sm:text-base lg:text-lg 
                font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Green Cycle
                  </button>
                </div>
              </div>
              <div
                className='flex items-center justify-center 
            sm:text-base 
            md:text-base lg:text-2xl font-bold'
              >
                Practicing Cycles gives us <br />
                opportunities for Jesus to <br />
                shine into our hearts, bring <br />
                inner healing, free us to <br />
                become whole, and draw us <br />
                closer to one another <br />
                through the Holy Spirit.
              </div>
              <div className='grid grid-cols-2 gap-4 place-content-evenly h-48'>
                <div className='flex items-center justify-center pt-10'>
                  <button
                    className=' 
                btn bg-primary text-sm sm:text-base lg:text-lg 
                font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Maturity Cycle
                  </button>
                </div>
                <div className='flex items-center justify-center pt-10'>
                  <button
                    className=' 
                btn bg-primary text-sm sm:text-base lg:text-lg 
                font-bold text-yellow-200 w-full sm:w-auto'
                  >
                    Wholeness Cycle
                  </button>
                </div>
              </div>
              <div className='font-bold md:text-2xl pt-20'>
                <h1>
                  The Four-Petal Sunflower visual and other Sunflower Tool
                  visuals help connect our inner hearts to the pure heart of
                  God.
                </h1>
              </div>
              <div className='flex items-center justify-center pt-20'>
                <Image src={Logo_Bottom} alt='Logo Bottom' />
              </div>
              <div className='flex items-center justify-center pt-20 md:text-2xl font-bold'>
                <Link to='/' style={{ color: 'blue' }}>
                  <u>Ephesians 3:14-21</u>
                </Link>
              </div>
              <div className='flex items-center justify-center md:text-2xl font-bold'>
                <h1>Paul's prayer is our prayer for you and us.</h1>
              </div>
              <div className='font-bold md:text-2xl pt-20'>
                <h1>
                  Hope you will share this website link with a friend or two and
                  together consider connecting with us.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}
export default Home
