import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/layout/Navbar';
import Home from './Pages/Home';
import About from './Pages/About';
import Footer from './Components/layout/Footer';
import NotFound from './Pages/NotFound';
import ConnectWithUs from './Pages/ConnectWithUs';
import Testimonies from './Pages/Testimonies';

import sunflowerBackgroundImage from "./images/Sunflower_disk.jpg";
import ComingSoon from './Pages/ComingSoon';
import TenKeys from './Pages/TenKeys';
import SunflowerTool from './Pages/SunflowerTool';

function App() {

return (
  <Router>
    <div
      className='flex flex-col justify-between h-screen bg-cover'
      style={{
        backgroundImage: `url(${sunflowerBackgroundImage})`,
        backgroundSize: 'cover',
        height: '100%'
      }}
    >
      <Navbar title='Sunflower tool' />
      <main className='container mx-auto'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/connectwithus' element={<ConnectWithUs />} />
          <Route path='/testimonies' element={<Testimonies />} />
          <Route path='/notfound' element={<NotFound />} />
          <Route path='/*' element={<NotFound />} />
          <Route path='/comingsoon' element={<ComingSoon />} />
          <Route path='/tenkeys' element={<TenKeys />} />
          <Route path='/sunflowertool' element={<SunflowerTool />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
)
}

export default App;
