import React from 'react'

function Testimonies() {
  return (
    <div className='text-lg bg-gray-700 text-primary-content text-center'>
        <h1>Coming Soon</h1>
    </div>
  )
}

export default Testimonies
