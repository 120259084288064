import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from '../../images/logo_in_the_bottom.png'


function Navbar({title}) {
  return (
    <nav className='navbar sm-12 shadow-lg bg-gray-700 text-white'>
      <div className='container mx-auto'>
        <div className='flex-none px-2 mx-2'>
          <img src={Logo} alt="logo" className='inline pr-2 text-3xl w-10' />
          <Link to='/' className='text-lg font-bold align-middle'>
            {title}
          </Link>
        </div>
        <div className="flex-1 px-2 mx-2">
          <div className="flex justify-end">
            <Link to="/" className="btn btn-ghost btn-sm rounded-btn">Home</Link>
            <Link to='/connectwithus' className="btn btn-ghost btn-sm rounded-btn">Connect With Us</Link>
            <Link to="/about" className="btn btn-ghost btn-sm rounded-btn">About Us</Link>
            <Link to="/comingsoon" className="btn btn-ghost btn-sm rounded-btn">Testimonies</Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
