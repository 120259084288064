
import Carousel from "react-bootstrap/Carousel";
import TopOfPage from "../images/top_of_page.png";
import Slide2 from "../images/slide_2.png"
import Slide3 from "../images/slide_3.png";
import Slide4 from "../images/slide_4.png";
import { Card } from "react-bootstrap";

function Slides() {
  return (
    <Carousel>
      <Carousel.Item>
        <img src={TopOfPage} alt='First slide' />
        <Carousel.Caption>
          <Card className='bg-custom-light-blue border-4 border-white'>
            <section className='text-1xl md:text-2xl'>
              <section className='font-bold'>
                Photo taken at Elliot Farm
              </section>
              <section>
                Lakeville MA USA <br></br> 2023
              </section>
            </section>
          </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={Slide2} alt='Second slide' />
        <Carousel.Caption>
          <Card className='sm:top-5 sm:pb-2 md:top-0 md:p-4 md:text-2xl border-4 border-white bg-custom-light-blue'>
            <section>
              <p className='font-bold'> Mark 1:14-15 </p>
              <section>
                <small className='md:text-2xl'>
                  Now after John was arrested, Jesus came into Galilee,
                  proclaiming the gospel of God, and saying, “The time is
                  fulfilled, and the kingdom of God is at hand; repent and
                  believe in the gospel.”
                </small>
              </section>
            </section>
          </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={Slide3} alt='slide 3' />
        <Carousel.Caption>
          <Card className='sm:top-5 sm:pb-2 md:top-0 md:p-4 md:text-2xl border-4 border-white bg-custom-light-blue'>
            <section>
              <p className='font-bold'> Matthew 6:10 </p>
              <section>
                <small className='md:text-2xl'>
                  Your kingdom come. Your will be done On earth as it is in
                  heaven.
                </small>
              </section>
            </section>
          </Card>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Slide4} alt='slide 3' />
        <Carousel.Caption>
          <Card className='sm:top-5 sm:pb-2 md:top-0 md:p-4 md:text-2xl border-4 border-white bg-custom-light-blue'>
            <section>
              <p className='font-bold'> Psalm 50:2 </p>
              <section>
                <small className='md:text-2xl'>
                  Out of Zion, the perfection of beauty, God shines forth.
                </small>
              </section>
            </section>
          </Card>
        </Carousel.Caption>
      </Carousel.Item>
      {/*

        <img className="sn_img" src={Slide4} alt="slide 4"></img>
        <Carousel.Caption>
            <section style={{ background: "black", marginRight:'10%', marginLeft:'10%', borderStyle:'solid', borderColor:'yellow' }}>
                <h2>
                  <b>
                    
                  </b>
                </h2> 
                <h3>
                  Out of Zion, the
                  perfection of beauty, God shines forth.
                </h3>
            </section>
        </Carousel.Caption>
      </Carousel.Item>*/}
    </Carousel>
  )
}
export default Slides;