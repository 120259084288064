import Card from '../Components/share/Card'
import tool1 from "../images/sunflowertoolimages/tool1.png"
import tool2 from '../images/sunflowertoolimages/tool2.png'
import tool3 from '../images/sunflowertoolimages/tool3.png'
import tool4 from '../images/sunflowertoolimages/tool4.png'
import tool5 from '../images/sunflowertoolimages/tool5.png'
import tool6 from '../images/sunflowertoolimages/tool6.png'
import tool7 from '../images/sunflowertoolimages/tool7.png'
import tool8 from '../images/sunflowertoolimages/tool8.png'
import tool9 from '../images/sunflowertoolimages/tool9.png'
import tool10 from '../images/sunflowertoolimages/tool10.png'
import tool11 from '../images/sunflowertoolimages/tool11.png'
import tool12 from '../images/sunflowertoolimages/tool12.png'
import tool13 from '../images/sunflowertoolimages/tool13.png'
import tool14 from '../images/sunflowertoolimages/tool14.png'
import tool15 from '../images/sunflowertoolimages/tool15.png'
import tool16 from '../images/sunflowertoolimages/tool16.png'
import tool17 from '../images/sunflowertoolimages/tool17.png'
import tool18 from '../images/sunflowertoolimages/tool18.png'
import tool19 from '../images/sunflowertoolimages/tool19.png'
import tool20 from '../images/sunflowertoolimages/tool20.png'
import tool21 from '../images/sunflowertoolimages/tool21.png'
import tool22 from '../images/sunflowertoolimages/tool22.png'
import tool23 from '../images/sunflowertoolimages/tool23.png'
import tool24 from '../images/sunflowertoolimages/tool24.png'
import tool25 from '../images/sunflowertoolimages/tool25.png'
import tool26 from '../images/sunflowertoolimages/tool26.png'
import tool27 from '../images/sunflowertoolimages/tool27.png'
import tool28 from '../images/sunflowertoolimages/tool28.png'
import tool29 from '../images/sunflowertoolimages/tool29.png'


function SunflowerTool() {
  return (
    <>
      <div className='m-7 p-10 bg-blue-50'>
        <Card>
          <div className='mb-7 bg-blue-50'>
            <Card>
              <img src={tool1} alt='tool 1'/>
            </Card>
          </div>
          <div className='mb-7 bg-blue-50'>
            <Card>
              <img src={tool2} alt='tool 2' />
            </Card>
          </div>
          <div className='mb-7 '>
            <Card>
              <img src={tool3} alt='tool 3' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool4} alt='tool 4' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool5} alt='tool 5' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool6} alt='tool 6' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool7} alt='tool 7' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool8} alt='tool 8' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool9} alt='tool 9' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool10} alt='tool 10' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool11} alt='tool 11' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool12} alt='tool 12' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool13} alt='tool 13' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool14} alt='tool 14' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool15} alt='tool 15' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool16} alt='tool 16' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool17} alt='tool 17' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool18} alt='tool 18' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool19} alt='tool 19' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool20} alt='tool 20' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool21} alt='tool 21' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool22} alt='tool 22' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool23} alt='tool 23' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool24} alt='tool 24' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool25} alt='tool 25' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool26} alt='tool 26' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool27} alt='tool 27' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool28} alt='tool 28' />
            </Card>
          </div>
          <div className='mb-7'>
            <Card>
              <img src={tool29} alt='tool 29' />
            </Card>
          </div>
        </Card>
      </div>
    </>
  )
}

export default SunflowerTool
