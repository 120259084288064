import React from 'react'
import AboutUsImage from '../images/aboutus.png'

function About() {
  return (
    <div className='flex justify-center items-center min-h-screen p-4'>
        <img 
            src={AboutUsImage}
            alt='About Us'
            className='w-full h-auto rounded-lg shadow-xl transition-transform hover:scale-105 hover:shadow-2xl'
        />

    </div>
  )
}

export default About
